import { drawer } from "./_drawer";
import { swiper } from "./_swiper";
import { scrollToTop } from "./_scrollToTop";
import { sangoCustoum } from "./_sangoCustoum";
import { header } from "./_header";


// ページの内容が完全に読み込まれた後のイベント
document.addEventListener('DOMContentLoaded', () => {
    // メインタグのID取得
    const mainElement = document.querySelector('main');
    const pageId = mainElement ? mainElement.getAttribute('id') : null;

    // ページ固有の関数実行
    const pageFunctions = {
        // 'top': [swiper],
    };

    header();
    drawer();
    swiper();
    scrollToTop();
    sangoCustoum();

    // ページ固有の関数を実行
    if (pageId && pageFunctions[pageId]) {
        pageFunctions[pageId].forEach(fn => {
            if (typeof fn === 'function') {
                try {
                    fn();
                } catch (error) {
                    console.error(`ページID ${pageId} の関数実行中にエラーが発生しました:`, error);
                }
            } else {
                console.error(`ページID ${pageId} に対して関数が期待されましたが、${typeof fn} が得られました`);
            }
        });
    }
});