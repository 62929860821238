export function swiper() {
    const swiper01 = new Swiper('.swiper01', {
        slidesPerView: 3, // 一度に表示するスライドの数
        spaceBetween: 37, // 各スライドの幅
        speed: 2000, // スライドの切り替え速度（ミリ秒）
        allowTouchMove: true, // スワイプを有効にする
        navigation: {
            nextEl: '.swiper-button-next.swiper-button-next01',
            prevEl: '.swiper-button-prev.swiper-button-prev01',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 37,
            },
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    });
    const swiper02 = new Swiper('.swiper02', {
        slidesPerView: 3,
        spaceBetween: 37,
        speed: 2000,
        allowTouchMove: true,
        navigation: {
            nextEl: '.swiper-button-next.swiper-button-next02',
            prevEl: '.swiper-button-prev.swiper-button-prev02',
        },
        breakpoints: {
            768: {
                slidesPerView: 3,
                spaceBetween: 37,
            },
            0: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
    });
}