export function header() {
    // ヘッダーの高さを取得
    const header = document.querySelector('.p-header');
    const headerHeight = header.offsetHeight; // ヘッダーの高さ取得

    window.addEventListener('scroll', function() {
        // スクロール量がヘッダーの高さを超えたらクラスを追加、そうでなければ削除
        if (window.scrollY > headerHeight) {
            header.classList.add('change-color');
        } else {
            header.classList.remove('change-color');
        }
    });
};