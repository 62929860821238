export function drawer() {
    const hamburgerButton = document.querySelector('#js-buttonHamburger');
    const nav = document.querySelector(".p-header__nav");

    hamburgerButton.addEventListener('click', (e) => {
        const isExpanded = e.currentTarget.getAttribute("aria-expanded") !== "false";
        e.currentTarget.setAttribute("aria-expanded", !isExpanded);
        document.documentElement.classList.toggle("is-drawerActive");
        nav.classList.toggle("is-active");

        var body = document.body;
        var bodyStyle = window.getComputedStyle(body);
        if (bodyStyle.overflow === "hidden") {
            body.style.height = "";
            body.style.overflow = "";
        } else {
            body.style.height = "auto";
            body.style.overflow = "hidden";
        }
    });

    document.querySelectorAll('js-header__nav-link').forEach(item => {
        item.addEventListener('click', () => {
            document.documentElement.classList.remove("is-drawerActive");
            nav.classList.remove("is-active");
            hamburgerButton.setAttribute("aria-expanded", "false");
            var body = document.body;
            body.style.height = "";
            body.style.overflow = "";
        });
    });

    const navItems = document.querySelectorAll(".js-header__nav-list-link-btn");
    // ウィンドウの幅が768px以下の場合のみイベントリスナーを追加する
    if (window.innerWidth <= 768) {
        const navLink = document.querySelectorAll(".p-header__nav-list-link-parent");
        navItems.forEach(item => {
            item.addEventListener('click', () => {
                const isActive = item.classList.contains("is-accordionActive");
                if (isActive) {
                    item.classList.remove("is-accordionActive");
                    navLink.forEach(el => el.classList.remove("is-accordionActive"));
                    return;
                }
                navItems.forEach(el => el.classList.remove("is-accordionActive"));
                navLink.forEach(el => el.classList.remove("is-accordionActive"));
                item.classList.add("is-accordionActive");
                const index = Array.from(navItems).indexOf(item);
                navLink[index].classList.add("is-accordionActive");
            });
        });
    }
};