export function sangoCustoum() {
  // .prnx.pr p と .prnx.nx p の両方の p 要素を選択
  document.querySelectorAll('.prnx.pr p, .prnx.nx p').forEach(function(p) {
    // p要素内の全ての子ノードを処理
    p.childNodes.forEach(function(node) {
        // テキストノードかどうかを確認
        if (node.nodeType === Node.TEXT_NODE) {
            // テキストノードを非表示にする（テキストを空にする）
            node.textContent = '';
        }
    });
  });
}
